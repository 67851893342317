"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
const imgCache = {};
function importAll(r) {
    r.keys().forEach((key) => (imgCache[key] = r(key).default));
}
//@ts-ignore
importAll(require.context('./assets', false, /\.(svg|webp)$/));
class VolumecalculatorCtrl {
    constructor($scope, actionService) {
        this.$scope = $scope;
        this.actionService = actionService;
        this.imgCache = imgCache;
        this.kind = JSON.parse(localStorage.getItem('options') || '{"kind": "sphere"}').kind;
        this.subKind = JSON.parse(localStorage.getItem('options') || '{"kind": "sphere"}').subKind;
        this.values = {};
        this.hasError = false;
        this.$scope.$watch(() => {
            return [this.kind, this.subKind];
        }, (value) => {
            localStorage.setItem('options', JSON.stringify({
                kind: value[0],
                subKind: value[1],
            }));
        }, true);
    }
    setKind(kind, subKind) {
        this.kind = kind;
        if (this.kind == 'prism_triangle') {
            //@ts-ignore
            this.subKind = ['base&height', '3sides', '2sides_angle', '2sides_side'].indexOf(subKind) != -1 ? subKind : '3sides';
        }
        else {
            this.subKind = undefined;
        }
        if (this.volForm)
            this.clear(this.volForm);
    }
    $onInit() {
    }
    angleToRadians(angle) {
        return angle * (Math.PI / 180);
    }
    radianToDegree(radian) {
        return radian * (180 / Math.PI);
    }
    sin(value) {
        return Math.sin(this.angleToRadians(value));
    }
    cos(value) {
        return Math.cos(this.angleToRadians(value));
    }
    submitForm($form) {
        if ($form.$valid && (Object.keys(this.values).length != 0)) {
            const result = {
                values: ng.copy(this.values)
            };
            this.hasError = false;
            try {
                if (this.kind == 'sphere') {
                    result.volume = (4 / 3) * Math.PI * Math.pow(this.values.r, 3);
                }
                else if (this.kind == 'cylinder') {
                    result.volume = Math.PI * Math.pow(this.values.r, 2) * this.values.h;
                }
                else if (this.kind == 'cube') {
                    result.volume = Math.pow(this.values.a, 3);
                }
                else if (this.kind == 'cone') {
                    result.volume = (1 / 3) * Math.PI * Math.pow(this.values.r, 2) * this.values.h;
                }
                else if (this.kind == 'prism_box') {
                    result.volume = this.values.l * this.values.h * this.values.w;
                }
                else if (this.kind == 'prism_triangle') {
                    if (this.subKind == 'base&height') {
                        result.volume = this.values.h * (1 / 2) * this.values.b * this.values.hb;
                    }
                    else if (this.subKind == '3sides') {
                        const s = (this.values.a + this.values.b + this.values.c) / 2;
                        const A = s * (s - this.values.a) * (s - this.values.b) * (s - this.values.c);
                        if (A >= 0) {
                            result.volume = this.values.h * Math.sqrt(A);
                        }
                        else {
                            throw "can't calculate";
                        }
                    }
                    else if (this.subKind == '2sides_angle') {
                        result.volume = this.values.h * (1 / 2) * this.values.a * this.values.b * this.sin(this.values.Y);
                    }
                    else if (this.subKind == '2sides_side') {
                        result.volume = this.values.h * Math.pow(this.values.a, 2) * this.sin(this.values.B) * this.sin(this.values.Y) / (2 * this.sin(this.values.B + this.values.Y));
                    }
                }
                else if (this.kind == 'pyramid') {
                    result.volume = (1 / 3) * Math.pow(this.values.a, 2) * this.values.h;
                }
                else if (this.kind == 'capsule') {
                    result.volume = Math.PI * Math.pow(this.values.r, 2) * this.values.h + (4 / 3) * Math.PI * Math.pow(this.values.r, 3);
                }
                else if (this.kind == 'hemisphere') {
                    result.volume = (2 / 3) * Math.PI * Math.pow(this.values.r, 3);
                }
                else if (this.kind == 'spherical_cap') {
                    result.volume = (Math.PI * Math.pow(this.values.h, 2) / 3) * (3 * this.values.r - this.values.h);
                }
                else if (this.kind == 'tube') {
                    result.volume = (Math.PI * Math.pow(this.values.D, 2) * this.values.h) - (Math.PI * Math.pow(this.values.d, 2) * this.values.h);
                }
                else if (this.kind == 'frustum') {
                    result.volume = (Math.PI * this.values.h / 3) * (Math.pow(this.values.R, 2) + this.values.R * this.values.r + Math.pow(this.values.r, 2));
                }
                else if (this.kind == 'truncated_pyramid') {
                    result.volume = (this.values.h / 3) * (Math.pow(this.values.a, 2) + Math.pow(this.values.b, 2) + this.values.a * this.values.b);
                }
                else if (this.kind == 'ellipsoid') {
                    result.volume = (4 / 3) * Math.PI * this.values.a * this.values.b * this.values.c;
                }
                if (Object.keys(result).indexOf('volume') != -1) {
                    if (isNaN(result.volume) || result.volume == null) {
                        throw "Volume is wrong calculated";
                    }
                    this.result = result;
                }
                else {
                    this.result = undefined;
                }
            }
            catch (e) {
                console.error(e);
                this.result = undefined;
                this.hasError = true;
            }
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.hasError = false;
        this.result = undefined;
        this.values = {};
    }
    copy(id) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), this.result.volume);
        }
    }
}
VolumecalculatorCtrl.$inject = ['$scope', 'ActionService',];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameVolumecalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: VolumecalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('volumecalculator/');
    }]);
